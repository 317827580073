#root {
  height: 100%;
}

@font-face {
  font-family: PoppinsRegular;
  src: url(../../assets/font/poppins_regular.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(../../assets/font/poppins_bold.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(../../assets/font/poppins_semi_bold.ttf);
}

@font-face {
  font-family: PoppinsMedium;
  src: url(../../assets/font/poppins_medium.ttf);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 100%;
  h1 {
    font-family: PoppinsBold;
  }
  span {
    font-family: PoppinsMedium;
  }
}

body {
  box-sizing: border-box;
  font-family: "PoppinsRegular";
}
