.react-datepicker {
  background: #e6f4f4 !important;
}
.react-datepicker__header {
  background: #f75353 !important;
}
.drop-down-custom button {
  background: none !important;
  border: none !important;
}

.drop-down-custom .btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}
.drop-down-custom .dropdown-header {
  box-shadow: none !important;
}
.bg-disable {
  background: #f2f2f2 !important;
  color: #808080 !important;
  border: 1px solid #e6e6e6 !important;
}
.modal-content {
  border: none !important;
  background: none !important;
}
.nav-item {
  a:hover {
    text-decoration: none !important;
    opacity: 0.8;
  }
  .active {
    span {
      border-bottom: 2px solid white;
    }
  }
}
.table-responsive {
  height: 750px !important;
  margin-bottom: 5rem;
}
