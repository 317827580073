.bg-red {
  background: #f75353 !important;
}
.bg-report {
  background: #e6f4f4 !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-15 {
  font-size: 15px !important;
}
